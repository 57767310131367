import styled from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${atMinWidth.sm`
     gap: 48px;
  `}
  ${atMinWidth.lg`
     gap: 64px;
  `}

  .cta-container {
    display: flex;
    justify-content: center;
  }
`;
const gridMap = {
  1: `grid-template-columns: repeat(2, 1fr);`,
  2: `grid-template-columns: repeat(2, 1fr);`,
  4: `grid-template-columns: repeat(4, 1fr);`,
};
export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${atMinWidth.sm`
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 30px;
 `}
  ${atMinWidth.lg`
   display: grid;
   ${({ numOfCards }) =>
     gridMap[numOfCards] || `grid-template-columns: repeat(3, 1fr);`}
   ${({ wide }) => (wide ? `grid-template-columns: repeat(2, 1fr);` : '')}
   gap: 30px;
 `}
  .cardBgHex {
    position: absolute;
    top: -30px;
    right: -5px;
    width: 200px;
    z-index: 100;
  }
`;
